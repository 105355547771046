import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "@flogy/gatsby-theme-fgs-layout";
import { SiteLayout } from "../components/SiteLayout";
import { NextEvents } from "../components/NextEvents";
import { Seo } from "../components/Seo";

const IndexPage = () => {
  return (
    <SiteLayout>
      <section className="bg-transparent text-white px-10 md:px-16 py-12 md:p-24">
        <div className="container mx-auto max-w-5xl">
          <span className="block text-inherit mt-6 mb-3 sm:mt-12 sm:mb-5 first:mt-0 tracking-wider leading-relaxed font-lexend text-3xl sm:text-5xl uppercase">
            Willkommen bei
          </span>
          <StaticImage
            src="../images/logo-farbig.png"
            alt="exSPIRIence Logo"
            placeholder="none"
            layout="constrained"
            width={400}
          />
        </div>
      </section>
      <section className="bg-salmon-400 text-white px-10 md:px-16 py-12 md:p-24">
        <div className="container mx-auto max-w-5xl">
          <div className="flex flex-col sm:flex-row gap-x-4">
            <div className="basis-1/3">
              <h2>Wer wir sind:</h2>
            </div>
            <div className="basis-2/3">
              <p>
                Du möchtest Zeit mit anderen Gläubigen verbringen und suchst
                nach einer Ergänzung oder Alternative zum gewöhnlichen
                Gottesdienst? Oder vielleicht sehnst du dich allgemein nach
                einem tieferen Sinn im Leben, doch in die Kirche zu gehen ist
                dir zu fromm?
              </p>
              <p>
                Wir stellen jedes Jahr ein vielfältiges Programm zusammen um
                Gott auf eine neue Art und Weise zu spüren und zu erleben.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white text-slate-700 px-10 md:px-16 py-12 md:p-24">
        <div className="container mx-auto max-w-5xl">
          <div className="flex flex-col sm:flex-row gap-x-4">
            <div className="basis-1/3">
              <h2>Unsere nächsten Events:</h2>
            </div>
            <div className="basis-2/3">
              <p>
                Schon bald ist unser nächster Event. Melde dich heute noch an um
                nichts zu verpassen. Wir freuen uns auf dich!
              </p>
              <Link href="/programm">
                <button className="mt-8">Alle anzeigen</button>
              </Link>
            </div>
          </div>
          <div className="mt-4">
            <NextEvents />
          </div>
        </div>
      </section>
      <section className="bg-transparent text-white px-10 md:px-16 py-12 md:p-24">
        <div className="container mx-auto max-w-5xl">
          <blockquote className="text-inherit mt-6 mb-3 sm:mt-12 sm:mb-5 first:mt-0 tracking-wider leading-relaxed font-lexend text-3xl sm:text-5xl text-center">
            "Whoever is generous to the poor lends to the lord, and he will
            repay him for his deed."
          </blockquote>
          <figcaption className="text-center">
            <cite>Proverbs 19:17</cite>
          </figcaption>
        </div>
      </section>
      <section className="bg-salmon-400 text-white px-10 md:px-16 py-12 md:p-24">
        <div className="container mx-auto max-w-5xl">
          <h2>Trete unserer Whatsapp Gruppe bei:</h2>
          <div className="flex flex-col-reverse sm:flex-row items-center sm:items-start gap-10">
            <StaticImage
              className="shrink-0"
              src="../images/whatsapp-qr-code.png"
              alt="QR Code mit Link zur exSPIRIence Whatsapp Gruppe"
              placeholder="none"
              layout="fixed"
              width={180}
            />
            <p className="mt-0">
              Dir ist es zu anstrengend dir alle unsere Events im Kalender
              einzutragen? Dann trete unserer{" "}
              <Link
                className="text-voodoo-700"
                href="https://chat.whatsapp.com/HJ9wsiyvlA8JMK4NvEZvBm"
              >
                Whatsapp Gruppe
              </Link>{" "}
              bei und werde von uns erinnert wann der nächste Event ansteht.
            </p>
          </div>
        </div>
      </section>
    </SiteLayout>
  );
};

export const Head = ({ location: { pathname } }) => (
  <Seo title="Startseite" relativePageUrl={pathname} />
);

export default IndexPage;
