import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { EventBoxSmall } from "./EventBoxSmall";

export const NextEvents = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityEvents(
        limit: 4
        sort: { fields: eventDate }
        filter: { isPastDay: { eq: false } }
      ) {
        nodes {
          eventDate(locale: "de", formatString: "D. MMM yyyy")
          eventEndDate(locale: "de", formatString: "D. MMM yyyy")
          eventCategory {
            title
            slug {
              current
            }
          }
          slug {
            current
          }
        }
      }
    }
  `);

  return (
    <div className="mt-10 grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {data.allSanityEvents.nodes.map((event) => {
        const dateString = event.eventEndDate
          ? `Ab ${event.eventDate}`
          : event.eventDate;

        return (
          <div key={event.slug.current}>
            <EventBoxSmall
              dateString={dateString}
              categoryName={event.eventCategory.title}
              linkUrl={`/programm/${event.eventCategory.slug.current}/${event.slug.current}`}
            />
          </div>
        );
      })}
    </div>
  );
};
