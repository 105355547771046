import * as React from "react";
import { Link } from "@flogy/gatsby-theme-fgs-layout";

interface Props {
  dateString: string;
  categoryName: string;
  linkUrl: string;
}

export const EventBoxSmall: React.FC<Props> = ({
  dateString,
  categoryName,
  linkUrl,
}) => (
  <Link
    className="group aspect-square p-4 bg-salmon-400 text-white hover:drop-shadow-xl hover:-translate-y-1 hover:no-underline transition flex flex-col"
    href={linkUrl}
  >
    <span className="grow text-inherit mt-6 mb-3 sm:mt-12 sm:mb-5 first:mt-0 tracking-wider leading-relaxed font-lexend font-bold text-2xl sm:text-4xl uppercase">
      {dateString}
    </span>
    <div className="flex flex-row items-end">
      <span className="grow text-inherit mt-6 sm:mt-12 first:mt-0 tracking-wider leading-relaxed font-lexend text-xl sm:text-2xl uppercase min-w-0">
        {categoryName}
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="min-w-12 w-12 min-h-12 h-12 group-hover:translate-x-3 transition-transform"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
    </div>
  </Link>
);
